import EntityRepository from '@/core/repository/entityRepository'
import { typeOf } from '@/helpers/type'
import File from '@/model/file'

export default new class FileRepository extends EntityRepository {
  baseUri() {
    return 'files'
  }

  mapEntity(props, prev = null) {
    if (props == null) {
      return null
    }
    let ent = prev
    if (!typeOf(ent, File)) {
      ent = new File()
    }
    if (props.hasOwnProperty('uuid')) {
      ent.uuid = props.uuid
    }
    if (props.hasOwnProperty('href')) {
      ent.href = props.href
    }
    if (props.hasOwnProperty('hrefThumbnail')) {
      ent.hrefThumbnail = props.hrefThumbnail
    }
    if (props.hasOwnProperty('originName')) {
      ent.originName = props.originName
    }
    if (props.hasOwnProperty('context')) {
      ent.context = props.context
    }

    return ent
  }

  post = async (data, params) => this.httpClient()
    .postFile(`${this.baseUri()}`, data, params)
}()
