import Entity from './entity'

export default class File extends Entity {
  /** @type String */
  uuid;
  /** @type String */
  href;
  /** @type String */
  hrefThumbnail;
  /** @type String */
  originName;
  /** @type String */
  context;
}
