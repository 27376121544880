export default class Pagination {
  /** @type {Entity[]} */
  items
  /** @type {Number} */
  last
  /** @type {Number} */
  current
  /** @type {Number} */
  numItemsPerPage
  /** @type {Number} */
  first
  /** @type {Number} */
  pageCount
  /** @type {Number} */
  totalCount
  /** @type {Number} */
  pageRange
  /** @type {Number} */
  startPage
  /** @type {Number} */
  endPage
  /** @type {Number[]} */
  pagesInRange
  /** @type {Number} */
  firstPageInRange
  /** @type {Number} */
  lastPageInRange
  /** @type {Number} */
  currentItemCount
  /** @type {Number} */
  firstItemNumber
  /** @type {Number} */
  lastItemNumber

  constructor(items = [], pager = {}) {
    this.items = items
    this.last = pager.last || 0
    this.current = pager.current || 0
    this.numItemsPerPage = pager.numItemsPerPage || 0
    this.first = pager.first || 0
    this.pageCount = pager.pageCount || 0
    this.totalCount = pager.totalCount || 0
    this.pageRange = pager.pageRange || 0
    this.startPage = pager.startPage || 0
    this.endPage = pager.endPage || 0
    this.pagesInRange = pager.pagesInRange || []
    this.firstPageInRange = pager.firstPageInRange || 0
    this.lastPageInRange = pager.lastPageInRange || 0
    this.currentItemCount = pager.currentItemCount || 0
    this.firstItemNumber = pager.firstItemNumber || 0
    this.lastItemNumber = pager.lastItemNumber || 0
  }

  isNew() {
    return this.current === 0
  }

  isEmpty() {
    return this.totalCount <= 0
  }
}
