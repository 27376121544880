import Entity from '@/model/entity'
import Pagination from '@/model/pagination'
import { typeOf } from '@/helpers/type'
import SuperRepository from '@/core/repository/superRepository'

export default class EntityRepository extends SuperRepository {
    /**
     * @param params {Object}
     * @returns {Promise<Pagination>}
     */
    paginate = async (params) => {
      return this.list(params).then((resp) => {
        return new Pagination(this.mapCollection(resp.data.payload.items), resp.data.payload.pagination)
      })
    };

    /**
     * @param id {String}
     * @returns {Promise<Entity>}
     */
    find = async (id) => {
      if (!typeOf(id, String)) {
        throw new TypeError(`Agrument must be of type <${String.name}>`)
      }
      return this.get(id).then(({ data }) => this.mapEntity(data.payload))
    };

    /**
     * @param entity {Entity}
     * @returns {Promise<Object>}
     */
    save = async (entity) => {
      if (!typeOf(entity, Entity)) {
        throw new TypeError(`Agrument must be of type <${Entity.name}>`)
      }
      if (entity.isNew()) {
        return this.post(entity.persistentProps()).then(({ data }) => {
          this.mapEntity(data.payload, entity)
        })
      }
      return this.patch(entity.id(), entity.persistentProps()).then(({ data }) => {
        this.mapEntity(data.payload, entity)
      })
    };

    /**
     * @param obj {Entity|String}
     * @returns {Promise<Object>}
     */
    remove = async (obj) => {
      let id = obj
      if (typeOf(obj, Entity)) {
        if (obj.isNew()) {
          return
        }
        id = obj.id()
      }
      if (!typeOf(id, String)) {
        throw new TypeError(`Agrument must be of type <${Entity.name}|${String.name}>`)
      }
      // eslint-disable-next-line consistent-return
      return this.delete(id)
    };

    /**
     * Factory method
     * @override
     * @returns {Entity}
     * @param args
     * @param prev {Entity|null}
     */
    mapEntity(args, prev = null) {
      if (args == null) {
        return null
      }
      if (typeOf(prev, Entity)) {
        return prev
      }
      return new Entity()
    }

    /**
     * Factory method
     * @returns {Entity[]}
     * @param arr {Object[]}
     */
    mapCollection(arr = []) {
      if (!typeOf(arr, Array)) {
        throw new TypeError(`Agrument must be of type <${Array.name}>`)
      }
      return arr.map(this.mapEntity)
    }
}
