<template>
<div>
  <b-container fluid class="p-0">
    <template v-for="item in orderItems">
      <div :key="item.key">
        <h5 class="text-dark font-weight-bold mb-3 py-3 pl-3">{{ item.name }}</h5>
        <FilesUpload v-model="item.files" />
        <hr>
      </div>
    </template>
    <h5 class="text-dark font-weight-bold mb-3 py-3 pl-3">{{ $t('ORDER.FILES.OTHER_FILES') }}</h5>
    <FilesUpload v-model="filesGlobal" />
    <b-row>
      <b-col cols="12">
        <div class="text-right mt-5">
          <div class="btn btn-secondary font-weight-bold btn-sm mr-3" @click="goBack">
            {{ $t('BASE.BACK') }}
          </div>
          <b-button :disabled="loading" type="submit" size="sm" variant="info" @click="onSubmit">
            <b-spinner v-if="loading" small variant="light" />
            {{ $t('FORM.SAVE_AND_NEXT') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import { mapState } from 'vuex'
import RepoOrder from '@/core/repository/company/ordersRepository'
import RepoItem from '@/core/repository/company/inventoryItemRepository'
import FilesUpload from '../../UI/FilesUpload'

export default {
  name: 'FilesOrder',
  components: {
    FilesUpload,
  },
  data() {
    return {
      loaded: false,
      loading: false,
      orderItems: [],
      filesGlobal: [],
    }
  },
  computed: {
    ...mapState({
      order: (state) => state.order.item,
    }),
  },
  watch: {
    '$route.query': {
      handler(query, queryOld) {
        if (query.type === 'files' && query.order) {
          this.fetchData()
        }
        if (queryOld && queryOld.type === 'files') {
          this.onSave()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    fetchData() {
      RepoOrder.get(this.$route.query.order).then(({ data }) => {
        this.items = data.payload.lines
        this.filesGlobal = data.payload.files
        this.orderItems = data.payload.items
        this.loaded = true
      })
    },
    onSave() {
      this.loading = true
      const uuids = this.filesGlobal.map((m) => (m.dataFile ? m.dataFile.uuid : m.uuid))
      this.orderItems.forEach((item) => {
        const uuidsItem = item.files.map((m) => (m.dataFile ? m.dataFile.uuid : m.uuid))
        RepoItem.patch(item.uuid, { files: uuidsItem })
      })
      RepoOrder.patch(this.order.uuid, { files: uuids }).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    onSubmit() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          type: 'summary', 
        }, 
      })
    },
    goBack() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          type: 'setup', 
        }, 
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .bodyClass{
    padding: 12px;
  }
</style>
