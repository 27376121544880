export default class Entity {
  /** @returns {String} */
  id() {
    return this.hasOwnProperty('uuid') ? this.uuid : ''
  }

  /** @param {String} val */
  setId(val) {
    return this.uuid = val
  }

  /** @returns {Object} */
  persistentProps() {
    return {}
  }

  /** @returns {Boolean} */
  isNew() {
    return !this.id()
  }
}
