<template>

<b-row>
  <b-col cols="12">
    <div class="d-inline-block mx-3 my-3" style="vertical-align: top">
      <b-overlay :show="loading" variants="transparent" opacity="0.4" rounded="sm" style="display: grid">
        <div
          @click="uploadImage"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
          class="file-upload--multi symbol symbol-120 cursor-pointer"
        >
          <input
            ref="file"
            :accept="allowedFileTypes"
            @change="onChange"
            id="assetsFieldHandle"
            type="file"
            multiple
            name="fields[assetsFieldHandle][]"
            style="visibility: hidden; position:absolute;"
          >
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="/media/svg/icons/Files/Upload.svg" />
          </span>
        </div>
      </b-overlay>
    </div>
    <template v-if="value">
      <template v-for="item in value">
        <div
          v-if="checkIsImage(item)"
          :key="item.uuid"
          :style="{ 'background-image': `url(${getUrl(item.href)})` }"
          style="vertical-align: top"
          class="image-input image-input-empty image-input-outline mx-3 my-3"
        >
          <div class="image-input-wrapper" />
          <b-dropdown
            size="sm"
            variant="link"
            class="dropdown-file"
            dropup
            style="position: absolute; top: -10px; right: -10px"
            toggle-class="text-decoration-none p-0"
            no-caret
            right
            no-flip
          >
            <template #button-content>
              <div class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow btn-dropdown p-0">
                <span class="svg-icon svg-icon-md">
                  <inline-svg src="/media/svg/icons/General/Other1.svg" />
                </span>
              </div>
            </template>
            <b-dropdown-item @click="openGallery(item)">{{ $t('BASE.PREVIEW') }}</b-dropdown-item>
            <b-dropdown-item @click="removeFile(item)">{{ $t('BASE.DELETE') }}</b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-else :key="item.uuid" style="vertical-align: top" class="image-input image-input-empty image-input-outline mx-3 my-3">
          <div class="image-input-wrapper">
            <div class="d-flex flex-column justify-content-center align-items-center" style="height: 100%;">
              <span class="svg-icon svg-icon-3x svg-icon-primary">
                <inline-svg :src="getExtension(item.href)" />
              </span>
              <div class="text-dark-75 font-weight-bold mt-8 font-size-sm text-truncate" style="max-width: 100px">{{ item.originName }}</div>
            </div>
          </div>
          <b-dropdown
            size="sm"
            variant="link"
            class="dropdown-file"
            dropup
            style="position: absolute; top: -10px; right: -10px"
            toggle-class="text-decoration-none p-0"
            no-caret
            right
            no-flip
          >
            <template #button-content>
              <div class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow btn-dropdown p-0">
                <span class="svg-icon svg-icon-md">
                  <inline-svg src="/media/svg/icons/General/Other1.svg" />
                </span>
              </div>
            </template>
            <b-dropdown-item :href="getUrl(item.href)">{{ $t('BASE.PREVIEW') }}</b-dropdown-item>
            <b-dropdown-item @click="removeFile(item)">{{ $t('BASE.DELETE') }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </template>
  </b-col>
  <LightBox
    v-if="filesImage.length"
    ref="lightbox"
    :media="galleryFiles"
    :show-light-box="false"
  />
</b-row>
</template>

<script>
import LightBox from 'vue-image-lightbox'
import RepoFile from '@/core/repository/company/fileRepository'
import ModalConfirm from '@/components/modals/ModalConfirm'

const isImage = require('is-image')
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
  name: 'FilesUpload',
  components: {
    LightBox,
  },
  props: {
    value: null,
  },
  data() {
    return {
      loading: false,
      allowedFileTypes: [
        'image/*',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
      ],
      filelist: [], // Store our uploaded files
    }
  },
  computed: {
    getExtension() {
      // eslint-disable-next-line consistent-return
      return (href) => { // TODO: ???
        const extension = href.split('.').pop()
        try {
          if (require(`./../../../public/media/svg/file-extension/${extension}.svg`)) {
            return `/media/svg/file-extension/${extension}.svg`
          }
        } catch (e) {
          return '/media/svg/file-extension/file.svg'
        }
      }
    },
    galleryFiles() {
      return this.filesImage.map((item) => {
        return {
          thumb: this.getUrl(item.hrefThumbnail),
          src: this.getUrl(item.href),
          caption: item.originName,
        }
      })
    },
    checkIsImage() {
      return (item) => isImage(this.getUrl(item.href))
    },
    filesImage() {
      return this.value ? this.value.filter((item) => isImage(this.getUrl(item.href))) : []
    },
    getUrl() {
      return (href) => `${process.env.VUE_APP_BACKEND_ORIGIN}${href}`
    },
  },
  methods: {
    openGallery(item) {
      const index = this.filesImage.findIndex((f) => f.uuid === item.uuid)
      this.$refs.lightbox.showImage(index)
    },
    removeFile(item) {
      this.$modal.show(ModalConfirm, {
        handlers: {
          onConfirm: () => {
            const index = this.value.findIndex((f) => f.uuid === item.uuid)
            if (index >= 0) {
              // eslint-disable-next-line vue/no-mutating-props
              this.value.splice(index, 1) // TODO: Mutate prop
            }
          },
        },
      }, {
        height: 'auto',
        adaptive: true,
        clickToClose: false,
      })
    },
    uploadImage() {
      this.$refs.file.click()
    },
    onSumbit(item) {
      return this.addFile(item).then(({ data }) => {
        const uuids = this.value.map((m) => m.uuid)
        if (!uuids.includes(data.payload.uuid)) this.$emit('input', this.value.concat(data.payload))
      }).catch((err) => {
        if (err.response.data.code && +err.response.data.code === 400 && Object.keys(err.response.data.errors).length) {
          if (err.response.data.errors.file && err.response.data.errors.file[0]) {
            this.$bvToast.toast(err.response.data.errors.file[0], {
              title: this.$t('TOAST.ERROR'),
              variant: 'danger',
              autoHideDelay: 3000,
              solid: true,
            })
          }
        }
      })
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
      }
    },
    onChange() {
      this.loading = true
      this.filelist = [...this.$refs.file.files]
      this.asyncForEach(this.filelist, async (item, index) => {
        await this.onSumbit(item, index)
        if (index === this.filelist.length - 1) {
          this.$refs.file.value = null
          this.loading = false
        }
      })
    },
    addFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      return RepoFile.post(formData)
    },
    remove(i) {
      this.filelist.splice(i, 1)
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-gray-100')) {
        event.currentTarget.classList.add('bg-gray-100')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove('bg-gray-100')
    },
    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.remove('bg-gray-100')
    },
  },
}
</script>

<style lang="scss" scoped>
  .file-upload--multi {
    height: 120px;
    width: 120px;
    position: relative;
    border: 1px dashed #c2c2c2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
